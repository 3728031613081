var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "grey lighten-5" },
    [
      _c("h4", { staticClass: "text-center successWish" }, [
        _vm._v(" Thank You! ")
      ]),
      _c(
        "div",
        {
          staticClass: "justify-center successPage",
          attrs: { sm: "12", md: "2" }
        },
        [
          _c(
            "div",
            { staticClass: "justify-center mb-1 checkMarkImage" },
            [
              _c("v-img", {
                staticClass: "successCheckMark",
                attrs: {
                  src: require("@/assets/images/checkmark.png"),
                  alt: "search"
                }
              })
            ],
            1
          ),
          _c("p", { staticClass: "text-center pt-2 successText" }, [
            _vm._v(
              " Thank you for your order. We’ll notify you the status of your order. "
            )
          ]),
          _c("p", { staticClass: "text-center", attrs: { id: "mb-5" } }, [
            _c("strong", [
              _vm._v(" Web Order #: " + _vm._s(_vm.billDetails.id))
            ])
          ]),
          _vm.billDetails.payment_type == "card"
            ? _c("p", { staticClass: "text-center", attrs: { id: "mb-5" } }, [
                _c("strong", [
                  _vm._v(
                    " Web Transaction ID: " +
                      _vm._s(_vm.billDetails.stripe_payment_id)
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "md-layout rcpt-dwnld" }, [
            _c(
              "span",
              { on: { click: _vm.downloadBill } },
              [
                _vm._v("DOWNLOAD ORDER DETAILS "),
                _c("v-icon", [_vm._v("mdi-download")])
              ],
              1
            )
          ]),
          _c(
            "v-btn",
            {
              staticClass: "button-add-address mt-5",
              style: { left: "50%", transform: "translateX(-50%)" },
              on: { click: _vm.navigateToHome }
            },
            [_vm._v(" Continue "), _c("v-icon", [_vm._v("mdi-chevron-right")])],
            1
          )
        ],
        1
      ),
      !_vm.loadingReceipt
        ? _c("order-receipt", {
            ref: "receiptRef",
            attrs: { "bill-details": _vm.billDetails }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }