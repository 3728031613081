<template>
  <v-container class="grey lighten-5">
    <h4 class="text-center successWish">
      Thank You!
    </h4>
    <div
      class="justify-center successPage"
      sm="12"
      md="2"
    >
      <div class="justify-center mb-1 checkMarkImage">
        <v-img
          src="@/assets/images/checkmark.png"
          alt="search"
          class="successCheckMark"
        />
      </div>

      <p class="text-center pt-2 successText">
        Thank you for your order. We’ll notify you the status of your order.
      </p>
      <p
        id="mb-5"
        class="text-center"
      >
        <strong> Web Order #: {{ billDetails.id }}</strong>
      </p>
      <p
        v-if="billDetails.payment_type == 'card'"
        id="mb-5"
        class="text-center"
      >
        <strong>
          Web Transaction ID: {{ billDetails.stripe_payment_id }}</strong>
      </p>
      <div class="md-layout rcpt-dwnld">
        <span
          @click="downloadBill"
        >DOWNLOAD ORDER DETAILS <v-icon>mdi-download</v-icon>
        </span>
      </div>

      <v-btn
        class="button-add-address mt-5"
        :style="{ left: '50%', transform: 'translateX(-50%)' }"
        @click="navigateToHome"
      >
        Continue <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <order-receipt
      v-if="!loadingReceipt"
      ref="receiptRef"
      :bill-details="billDetails"
    />
  </v-container>
</template>
<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import { useRouter } from '../utils/utils'
import OrderReceipt from '../components/OrderReceipt.vue'

export default {
  components: {
    OrderReceipt,
  },
  setup() {
    const { router } = useRouter()

    const paymentId = ref('')

    const tableNo = ref('')

    const billDetails = ref({})

    const receiptRef = ref(null)

    const loadingReceipt = ref(true)

    const totalAmount = () => {
      let total = 0
      store.state.cart.cartItems.forEach(i => {
        total += i.price * i.qty
      })
      return total
    }

    const downloadBill = () => {
      if (receiptRef.value) {
        receiptRef.value.downloadBill(billDetails.value.id)
      }
    }

    const placeOrder = () => {
      const cloneData = JSON.parse(JSON.stringify(store.state.cart.cartItems))
      const orderTime = new Date()
      //   orderTime.setHours(selectedTime.value.split(':')[0])
      //   orderTime.setMinutes(selectedTime.value.split(':')[1])
      // const saleType = store.state.cart.orderType

      cloneData.forEach(element => {
        if (element.menu_modifiers) {
          element.menu_modifiers.forEach(menuModifier => {
            // eslint-disable-next-line no-param-reassign
            delete menuModifier.modifiers
          })
        }
      })
      const orderDetails = JSON.parse(localStorage.getItem('orderInfo'))

      tableNo.value = orderDetails.table_no || ''

      loadingReceipt.value = true
      store
        .dispatch('cart/placeOrder', {
          order: {
            cart_items: cloneData,
            amount: totalAmount(),
            order_date: orderTime,
            order_type: orderDetails.orderType,
            payment_status: !!(
              router.currentRoute.query.redirect_status === 'succeeded'
              && orderDetails.paymentMethod === 'card'
            ),
            stripe_payment_id: paymentId.value,
            payment_type: orderDetails.paymentMethod,
            discount: orderDetails.discountAmt,
            pickup_time: `${new Date().toISOString().slice(0, 10)} ${
              orderDetails.pickUpTime
            }`,
            instructions: orderDetails.instructions,
            table_no: orderDetails.table_no,
            guest_count: orderDetails.guest_count,
            phone: localStorage.getItem('phone') || orderDetails.phone,
            customer_name: orderDetails.customer_name,
          },
        })
        .then(resp => {
          billDetails.value = resp
          localStorage.removeItem('cart')
          localStorage.removeItem('paymentInfo')
          store.state.cart.cartItems = []
          loadingReceipt.value = false
        })
        .catch(() => {
          // console.log(err)
        })
    }

    if (
      router.currentRoute.query.redirect_status === 'succeeded'
      && localStorage.getItem('cart')
    ) {
      paymentId.value = router.currentRoute.query.payment_intent
      placeOrder()
    } else {
      router.push({ name: 'home' })
    }

    const navigateToHome = () => {
      if (tableNo.value !== '') {
        router.push({ name: 'home', params: { table_no: tableNo.value } })
      } else {
        router.push({ name: 'home' })
      }
    }

    return {
      navigateToHome,
      totalAmount,
      receiptRef,
      downloadBill,
      billDetails,
      loadingReceipt,
    }
  },
}
</script>
<style>
#hidden-bill {
  position: absolute;
  z-index: -9;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 20px;
}
#mb-5 {
  margin-bottom: 5px !important;
}
.rcpt-dwnld {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  cursor: pointer;
  background-color: #0cbc40;
  max-width: 225px;
  margin: auto;
  padding-bottom: 12px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
  padding-left: 7px;
  padding-right: 7px;
}

.rcpt-dwnld span {
  font-weight: 600;
}
.rcpt-dwnld i {
  color: #fff !important;
  font-size: 18px !important;
}
.rcpt-dwnld:hover {
  background-color: #62c780;
}
</style>
